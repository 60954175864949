import AttachedS3File from "@/models/AttachedS3File";
import BaseModel from "@/models/BaseModel";
import Cle from "@/models/Cle";
import Ebe from "@/models/Ebe";
import User from "@/models/User";

export default class Document extends BaseModel {
  static entity = "document";
  static resourceUrl = "/documents/document/";

  static fields() {
    return {
      id: this.uid(),
      content_file: this.attr(),
      name: this.attr(),
      tags: this.attr([]),
      cles: this.attr([]),
      ebes: this.attr([]),
      allowed_groups: this.attr([]),
      status_log: this.attr([]),

      // update file
      send_notification: this.attr(false),
    };
  }

  static PERMISSIONS = Object.freeze({
    READ: "documents:read_document",
    UPLOAD: "documents:upload_document",
  });

  static NOTIFICATIONS = Object.freeze({
    NEW_DOCUMENT: "documents.new_document_technical",
  });

  static STATUS = Object.freeze({
    A_COMPLETER: "A_COMPLETER",
    ACTIF: "ACTIF",
    ARCHIVE: "ARCHIVE",
  });

  static STATUSES = Object.freeze([
    {
      text: "À compléter",
      value: Document.STATUS.A_COMPLETER,
      color: "warning-bg",
      textColor: "secondary-high-contrast--text",
      icon: "mdi-pen",
      iconColor: "secondary-high-contrast",
    },
    {
      text: "Disponible",
      value: Document.STATUS.ACTIF,
      color: "success-bg",
      textColor: "success--text",
      icon: "mdi-check",
      iconColor: "success",
    },
    {
      text: "Archivé",
      value: Document.STATUS.ARCHIVE,
      color: "grey lighten-4",
      textColor: "grey--text text--darken-2",
      icon: "mdi-archive",
      iconColor: "grey",
    },
  ]);

  static NO_STATUS = Object.freeze({
    text: "Erreur",
    value: null,
    color: "error-bg",
    textColor: "error--text",
    icon: "mdi-close",
    iconColor: "error",
  });

  get isArchived() {
    return this.status.value === Document.STATUS.ARCHIVE;
  }

  get isActive() {
    return this.status.value === Document.STATUS.ACTIF;
  }

  get isIncomplete() {
    return this.status.value === Document.STATUS.A_COMPLETER;
  }

  get s3File() {
    if (!this.content_file) return null;
    return new AttachedS3File(this.content_file);
  }

  get structures() {
    return [Cle.findIn(this.cles), Ebe.findIn(this.ebes)].flat();
  }

  get uploadedDate() {
    return this.status_log.at(-1)?.datetime;
  }

  get modifiedDate() {
    return this.status_log[0]?.datetime;
  }

  get modifiedUser() {
    return User.find(this.status_log[0]?.user_id);
  }

  get status() {
    return Document.getStatus(this.status_log?.[0]?.value);
  }

  static getStatus(status) {
    const validStatus = Document.STATUSES.find((s) => s.value === status);
    return validStatus ?? Document.NO_STATUS;
  }

  static S3_TARGET_CONTENT_FILE = "documents.document.content_file";

  updateStatus(status) {
    return Document.api().updateStatus(this, status);
  }

  archive() {
    return this.updateStatus(Document.STATUS.ARCHIVE);
  }

  unarchive() {
    return this.updateStatus(Document.STATUS.ACTIF);
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      updateStatus(document, status) {
        const data = {
          id: document.id,
          new_status: status,
        };
        return this.post(`${this.model.resourceUrl}${document.id}/update_status/`, data);
      },
    },
  };
}
