import BaseModel from "@/models/BaseModel";

export default class BilanGensThematique extends BaseModel {
  static entity = "bilan_gens_thematique";
  static resourceUrl = "/bilan/bilan_gens_thematique/";

  static fields() {
    return {
      id: this.uid(),
      nom: this.attr(),
      description: this.attr(),
    };
  }
}
