<template>
  <div :class="`${$attrs.class ? $attrs.class : ''} support-disabled-items`">
    <v-tooltip
      :content-class="`base-tooltip base-tooltip--${color} py-2 px-3`"
      :max-width="350"
      v-bind="$attrs"
      :top="$attrs.top || !Object.keys($attrs).find((key) => key === 'right' || key === 'bottom' || key === 'left')"
    >
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="disabled ? null : on" :class="`tooltip-activator ${disabled ? 'disabled' : ''}`">
          <slot name="activator">
            <slot name="preActivator">{{ preActivator ? `${preActivator} ` : "" }}</slot>
            <v-icon v-if="!disabled" small :color="iColor || color" class="mt-n1">mdi-information-outline</v-icon>
            <slot name="postActivator">{{ postActivator ? ` ${postActivator}` : "" }}</slot>
          </slot>
        </span>
      </template>
      <slot />
    </v-tooltip>
  </div>
</template>
<script>
// these values are the one declared on the main.scss file
const COLORS = ["warning", "info", "error", "black", "success", "neutral"];
</script>
<script setup>
defineProps({
  color: {
    type: String,
    default: "warning",
    validator(value) {
      return COLORS.includes(value);
    },
  },
  preActivator: String,
  postActivator: String,
  disabled: {
    type: Boolean,
    default: false,
  },
  iColor: String,
});
</script>

<style lang="scss" scoped>
.base-tooltip {
  overflow-wrap: break-word;
}
.tooltip-activator:not(.disabled):hover {
  opacity: 0.5;
}

// Allow tooltips to be active on disabled inputs
.support-disabled-items {
  pointer-events: all;
}
</style>
