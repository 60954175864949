import { Model } from "@vuex-orm/core";

export default class Region extends Model {
  static entity = "region";
  static resourceUrl = "/regions/";

  static fields() {
    return {
      id: this.uid(),
      nom: this.attr(),
      code_insee: this.attr(),
    };
  }

  static state() {
    return {
      allLoaded: false,
    };
  }

  static async getOrLoad(id) {
    let region = this.query().where("id", id).first();
    if (region) return region;

    let response = null;
    try {
      response = await this.api().get(`/regions/${id}/`);
      return response.entities.region[0];
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static apiConfig = {
    actions: {
      loadAll() {
        let state = this.model.store().state.entities[this.model.entity];
        if (state.allLoaded) {
          return Promise.resolve();
        }
        return this.get(this.model.resourceUrl).then(() => {
          this.model.commit((state) => {
            state.allLoaded = true;
          });
        });
      },
      search(value, offset) {
        return this.get(this.model.resourceUrl, {
          params: {
            search: value,
            limit: 50,
            offset: offset,
          },
        });
      },
    },
  };
}
