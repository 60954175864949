import BaseModel from "@/models/BaseModel.js";
import EbeActiviteCategorie from "../notrexp/EbeActiviteCategorie";
import FECImport from "./FECImport";
import Ebe from "../Ebe";

export default class FECActiviteRealisee extends BaseModel {
  static entity = "fec_activite_realisee";
  static resourceUrl = "/fec/fec_activite_realisee/";

  static fields() {
    return {
      id: this.uid(),
      fec_import: this.attr(),
      activite: this.attr(),
      intitule: this.attr(),
      ca: this.attr(),
      subvention_exploitation: this.attr(),
      achat_consomme: this.attr(),
      nbr_moyen_etp_contractuel: this.attr(),
      nbr_moyen_etp_travailles: this.attr(),
      nbr_moyen_etp_travailles_original: this.attr(),
    };
  }

  getActivite() {
    return EbeActiviteCategorie.query().whereId(this.activite).first();
  }

  getEbe() {
    const fec = FECImport.query().whereId(this.fec_import).first();
    return Ebe.query().whereId(fec.ebe).first();
  }
}
