import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fr from "vuetify/lib/locale/fr";
import dayjs from "dayjs";
import InputFacade from "vue-input-facade";
import utc from "dayjs/plugin/utc";
import VueMatomo from "vue-matomo";

import router from "@/router";
import store from "@/stores";
import App from "@/App.vue";
import InputNumber from "@/components/utils/vuetify/InputNumber.vue";
import AppTitle from "@/components/utils/AppTitle.vue";
import AlertCard from "@/components/utils/AlertCard.vue";
import BaseTooltip from "@/components/utils/BaseTooltip.vue";
import "@/helpers/VueFilters.js";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import "@mdi/font/css/materialdesignicons.css";
import "@/assets/fonts/roboto.css";
import "@/styles/main.scss";

import EtcldIconComponent from "@/assets/icons/etcld.vue";
import EbeIconComponent from "@/assets/icons/ebe.vue";
import CleIconComponent from "@/assets/icons/cle.vue";
import UserActivityLog from "./models/notrexp/UserActivityLog";

dayjs.extend(utc);

Vue.config.productionTip = true;
Vue.use(Vuetify);
Vue.component("InputNumber", InputNumber);
Vue.component("AppTitle", AppTitle);
Vue.component("AlertCard", AlertCard);
Vue.component("BaseTooltip", BaseTooltip);
Vue.use(InputFacade, {
  // migrating from v-mask
  // rename the directive from: v-facade to: v-mask
  name: "mask",

  // use these tokens instead of the default
  tokens: {
    "#": { pattern: /\d/ },
    A: { pattern: /[a-z]/i },
    N: { pattern: /[0-9a-z]/i },
    X: { pattern: /./ },
  },
});

if (process.env.VUE_APP_MATOMO_INSTANCE_URL) {
  Vue.use(VueMatomo, {
    host: process.env.VUE_APP_MATOMO_INSTANCE_URL,
    siteId: process.env.VUE_APP_MATOMO_SITE_ID,
    router: router,
  });
  window._paq.push(["trackPageView"]);
  window._paq.push(["enableLinkTracking"]);
  // RGPD : https://matomo.org/faq/new-to-piwik/how-do-i-use-matomo-analytics-without-consent-or-cookie-banner/
  // Disable cookie tracking : https://matomo.org/faq/new-to-piwik/how-can-i-still-track-a-visitor-without-cookies-even-if-they-decline-the-cookie-consent/
  window._paq.push(["requireCookieConsent"]);
}

/** Add a sugar syntax directive to send an activityuserlog when an user action occured.
 * syntax: <v-btn v-log-activity:click="{element: 'an_element_id', title: 'A readable action'}">
 */
Vue.directive("log-activity", {
  inserted: function (el, binding, vnode) {
    if (binding.value.element == null || binding.value.title == null) {
      throw (
        "Bad arguments in v-log-action, you must provide an object with keys element " +
        "and title. Example: v-log-activity:click={element: " +
        "'rdv_pilotage/completer_saisie', title: 'Clic sur Compléter la saisie'}."
      );
    }
    let action_type = binding.arg;

    if (action_type == "click" && vnode.tag.indexOf("v-btn") != -1) {
      action_type = "BUTTON_CLICK";
    } else {
      throw "Action not found. The only combinaison accepted is v-log-activity:click on a v-btn.";
    }

    const { element: action_element, title, ebes, cles } = binding.value;

    el.addEventListener("click", function () {
      UserActivityLog.api().sendLog(action_type, action_element, title, ebes, cles);
    });
  },
});

if (process.env.VUE_APP_SENTRY_ENVIRONMENT && process.env.VUE_APP_SENTRY_ENVIRONMENT != "local") {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_BASE_URL, /^\//],
      }),
    ],
    tracingOptions: {
      trackComponents: true,
    },
    tracesSampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE,
    attachProps: true,
    attachStacktrace: true,
  });
}

const vuetify = new Vuetify({
  lang: {
    locales: { fr },
    current: "fr",
  },
  theme: {
    themes: {
      light: {
        primary: "#64498f",
        secondary: "#f68136",
        info: "#911260",
        success: "#007b05",
        warning: "#f68136",
        error: "#cc0202",
        "secondary-high-contrast": "#b05301",
        "blue-high-contrast": "#1565C0", // blue--text text--darken-3
        etcld: "#2196f3",

        "primary-bg": "#ebe8f1",
        "info-bg": "#f1e1eb", // picked from automatic AlertCard background
        "success-bg": "#dfeedf",
        "warning-bg": "#fdefe5",
        "error-bg": "#f8dfdf",
        "etcld-bg": "#dff1f0",
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    values: {
      "etcld-icon": {
        component: EtcldIconComponent,
      },
      "ebe-icon": {
        component: EbeIconComponent,
      },
      "cle-icon": {
        component: CleIconComponent,
      },
    },
  },
});

store.dispatch("init").then(() => {
  new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
