import BaseModel from "@/models/BaseModel.js";

import { formatToDisplay } from "@/helpers/dates.js";

const unknownStatus = { text: "Statut inconnu", icon: "mdi-alert", color: "red" };
export default class FECImport extends BaseModel {
  static entity = "fec_import";
  static resourceUrl = "/fec/fec_import/";

  static STATUS_PENDING = "PENDING";
  static STATUS_IMPORT_ERROR = "IMPORT_ERROR";
  static STATUS_ACTIVE = "ACTIVE";
  static STATUS_ACTIVE_ERROR = "ACTIVE_ERROR";
  static STATUS_VALIDATED_EBE = "VALIDATED_EBE";
  static STATUS_VALIDATED_ETCLD = "VALIDATED_ETCLD";
  static STATUS_INACTIVE = "INACTIVE";
  static STATUS_FERME_NON_VALIDE = "FERME_NON_VALIDE";

  static STATUSES = [
    { text: "À compléter par l'EBE", value: FECImport.STATUS_ACTIVE, color: "grey", icon: "mdi-pen", canEbeFilter: true },
    { text: "FEC non valide", value: FECImport.STATUS_ACTIVE_ERROR, color: "grey", icon: "mdi-alert", canEbeFilter: true },
    { text: "Validé par l'EBE", value: FECImport.STATUS_VALIDATED_EBE, color: "orange", icon: "mdi-check", canEbeFilter: true },
    { text: "Validé par ETCLD", value: FECImport.STATUS_VALIDATED_ETCLD, color: "green", icon: "mdi-check-all", canEbeFilter: true },
    { text: "Inactif", value: FECImport.STATUS_INACTIVE, color: "grey darken-1", icon: "mdi-delete", canEbeFilter: false },
    {
      text: "Fermé, non validé par l'EBE",
      value: FECImport.STATUS_FERME_NON_VALIDE,
      color: "grey",
      icon: "mdi-timer-lock-outline",
      canEbeFilter: true,
    },
    { text: "Erreur de traitement", value: FECImport.STATUS_IMPORT_ERROR, color: "red", icon: "mdi-alert", canEbeFilter: false },
    { text: "En cours de traitement", value: FECImport.STATUS_PENDING, color: "grey", icon: "mdi-timer-sand", canEbeFilter: false },
  ];

  static getStatus(statusName) {
    return FECImport.STATUSES.find((s) => s.value === statusName) || unknownStatus;
  }

  get status() {
    const lastStatus = this.status_log?.[0];
    return lastStatus ? { ...lastStatus, ...FECImport.getStatus(lastStatus.value) } : unknownStatus;
  }

  get lastValidStatus() {
    return this.status_log?.find((status) => status.value === FECImport.STATUS_VALIDATED_EBE || status.value === FECImport.STATUS_VALIDATED_ETCLD);
  }

  get validatedDate() {
    return this.lastValidStatus?.datetime;
  }

  get validatedBy() {
    return this.lastValidStatus.user ? `${this.lastValidStatus.user.first_name} ${this.lastValidStatus.user.last_name}` : null;
  }

  static ETCLD_SPECIFIC_ROWS = [
    "TITRE#TOTAL_SUBVENTIONS_2",
    "TITRE#FINANCEMENTS_5",
    "TITRE#DOTATION_AMORCAGE_7",
    "TITRE#CH_MASSE_PPDE_9",
    "TITRE#CH_MASSE_NPPDE_10",
    "TITRE#TOTAL_CDE_12",
    "TITRE#TOTAL_CDE_ETAT_13",
    "TITRE#TOTAL_CDE_DEPARTEMENT_14",
    "77153",
  ];

  static FEC_WARNING = "FEC_WARNING";
  static FEC_ERROR = "FEC_ERROR";
  static COMPTE_RESULTAT_WARNING = "COMPTE_RESULTAT_WARNING";
  static COMPTE_RESULTAT_ERROR = "COMPTE_RESULTAT_ERROR";

  static PURPOSE_TEST = "TEST";
  static PURPOSE_VALIDATION = "VALIDATION";

  static PLANS_COMPTABLES = [
    { text: "Société commerciale", value: "SOCIETE_COMMERCIALE" },
    { text: "Association", value: "ASSOCIATION" },
  ];

  static fields() {
    return {
      id: this.uid(),
      ebe: this.attr(),
      status_log: this.attr(),
      fec_start_date: this.attr(),
      fec_end_date: this.attr(),
      import_datetime: this.attr(),
      total_subventions: this.attr(),
      nbr_moyen_etp_contractuel: this.attr(),
      nbr_moyen_etp_travailles: this.attr(),
      nbr_moyen_etp_travailles_original: this.attr(),
      plan_comptable: this.attr(),
      use_new_plan_comptable: this.attr(),
      use_new_plan_comptable_comment: this.attr(),
      purpose: this.attr(),
    };
  }

  static PERMISSION = Object.freeze({
    READ: "fec:fecimport_read",
    UPDATE: "fec:fecimport_update",
    VALIDATE: "fec:fecimport_validate",
    UPDATE_STATUS: "fec:fecimport_update_status",
  });

  getStartYear() {
    return this.fec_start_date.split("-")[0];
  }

  getEndYear() {
    return this.fec_end_date.split("-")[0];
  }

  getPeriod() {
    return `${formatToDisplay(this.fec_start_date)} - ${formatToDisplay(this.fec_end_date)}`;
  }

  static compteResultatToDict(lines) {
    const compteResultat = {};
    for (let line of lines) {
      let lineCopy = { ...line };
      if (lineCopy?.montant) {
        lineCopy.montant = parseFloat(lineCopy.montant);
      }
      compteResultat[lineCopy.internal_label] = lineCopy;
    }

    return compteResultat;
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      upload({ ebe, files, startDate, endDate, planComptable, useNewPlanComptable, useNewPlanComptableComment }, config) {
        const request = new FormData();
        request.set("ebe", ebe);
        for (const file of files) {
          request.append("fec", file);
        }
        request.set("fec_start_date", startDate);
        request.set("fec_end_date", endDate);
        request.set("plan_comptable", planComptable);
        request.set("use_new_plan_comptable", useNewPlanComptable);
        request.set("use_new_plan_comptable_comment", useNewPlanComptableComment);

        return this.post(`${this.model.resourceUrl}upload/`, request, config);
      },
      async getAlerts(fecImportId) {
        const results = await this.get(`${this.model.resourceUrl}${fecImportId}/alerts/`);
        const data = results?.response?.data;
        if (!data) return null;

        return data.reduce((acc, value) => {
          const { type } = value;
          acc[type] = acc[type] ? [...acc[type], value] : [value];
          return acc;
        }, {});
      },
      checkUploadTask(id) {
        return this.post(`${this.model.resourceUrl}${id}/check_upload_task/`);
      },
      loadSummary(instance) {
        return this.get(`${this.model.resourceUrl}${instance.id}/summary/`);
      },
      loadCompteResultat(instance) {
        return this.get(`${this.model.resourceUrl}${instance.id}/compte_resultat/`);
      },
      validateFECImport(instance, data = {}, options = {}) {
        return this.post(`${this.model.resourceUrl}${instance.id}/validate/`, data, options);
      },
      getCSVFile(instance) {
        return this.get(`${this.model.resourceUrl}${instance.id}/csv_export/`);
      },
      loadAvailableYears() {
        return this.get(`${this.model.resourceUrl}available_years/`);
      },
      bulkAddStatus(realises, status) {
        const data = {
          status,
          fec_import_ids: realises.map((realise) => realise.id),
        };
        return this.post(`${FECImport.resourceUrl}update_status/`, data);
      },
    },
  };
}
