import BaseModel from "@/models/BaseModel.js";
import { EbePublic } from "@/models/Ebe";
import EbeActiviteCategorie from "../notrexp/EbeActiviteCategorie";

export default class FECActiviteRealiseePartage extends BaseModel {
  static entity = "fec_activite_realisee_partage";
  static resourceUrl = "/fec/fec_activite_realisee_partage/";

  static fields() {
    return {
      id: this.uid(),
      activite: this.attr(),
      intitule: this.attr(),
      nbr_moyen_etp_travailles: this.attr(),
      ebe: this.attr(),
      ca_and_subvention: this.attr(),
      marge_brut: this.attr(),
    };
  }

  getActivite() {
    return EbeActiviteCategorie.query().whereId(this.activite).first();
  }

  getEbePublic() {
    return EbePublic.query().whereId(this.ebe).first();
  }

  static async getMaxValues() {
    const results = await this.api().loadCaAndEtpRange();
    const caMax = results.response.data.max_ca;
    const etpMax = results.response.data.max_etp;
    return { caMax, etpMax };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      loadCaAndEtpRange() {
        return this.get(`${this.model.resourceUrl}compute_max_ca_and_etp_travaille_rounded/`);
      },
    },
  };
}
