import BaseModel from "@/models/BaseModel.js";

import Cle, { ClePublic } from "@/models/Cle";
import DepartementEntite, { DepartementEntitePublic } from "@/models/notrexp/DepartementEntite";
import GroupEntite from "@/models/notrexp/GroupEntite";
import User from "@/models/User";

export default class Ebe extends BaseModel {
  static entity = "ebe";
  static resourceUrl = "/ebe/";

  static fields() {
    return {
      id: this.uid(),
      nom: this.string(""),
      cles: this.attr(),
      embauche_premiers_date: this.attr(),

      // included in the createEbe
      utilisateur: this.attr(),

      // included in the extra_infos
      ca_conventionnement_date: this.attr(),
      code_ape: this.attr(),
      code_rna: this.attr(),
      opco: this.attr(),
      idcc: this.attr(),
      created_datetime: this.attr(),
      creation_personne_morale_date: this.attr(),
      debut_prise_en_charge_mois: this.attr(),
      siren: this.attr(),
      slug: this.attr(),
      statut: this.attr(),
      iban: this.attr([]),
      administrateurs_referents: this.attr([]),

      // included in the address_infos and extra_infos
      adresse: this.attr(),
      ville: this.attr(),
    };
  }

  static PERMISSION = Object.freeze({
    UPDATE_EXTRA_INFO: "notrexp:ebe_update_extra_info",
    MANAGE: "notrexp:ebe_manage",
    EXPORT_IBAN: "notrexp:ebe_iban_export",
  });

  get entityType() {
    return "EBE";
  }

  getCles() {
    return Cle.query().whereIdIn(this.cles).orderBy("nom").get();
  }

  getDepartements() {
    const cles = this.getCles();
    const departementIds = cles.flatMap(({ departements }) => departements);
    return DepartementEntite.query().whereIdIn(departementIds).orderBy("nom").all();
  }

  getGroups() {
    return GroupEntite.query()
      .where((group) => group.entites.includes(this.id))
      .orderBy("nom")
      .get();
  }

  getEmbauchePremiersDate() {
    if (!this.embauche_premiers_date) {
      return;
    }

    return new Date(this.embauche_premiers_date);
  }

  get relatedEntities() {
    const cles = this.getCles();
    const departements = this.getDepartements();

    return [...cles, ...departements];
  }

  get color() {
    return Ebe.getColor();
  }

  get icon() {
    return Ebe.getIcon();
  }

  static getCurrent() {
    const user = User.getCurrent();
    const ebeId = user.ebes.length === 1 ? user.ebes[0] : null;
    return this.query().whereId(ebeId).first();
  }

  static getColor() {
    return {
      text: "primary--text",
      background: "primary-bg",
      activeBackground: "primary",
    };
  }
  static getIcon() {
    return process.env.VUE_APP_DISABLE_NEW_ICONS ? "mdi-office-building" : "$ebe-icon";
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      load(params, vuexOrmOptions) {
        if (process.env.NODE_ENV === "development" && !User.getCurrent().isETCLD && !params) {
          console.warn(
            "Attention, `Ebe.api().load()` sans contexte est a éviter pour charger les EBE de l'utilisateur. Prévilégier `loadCurrent`/`getCurrent` ou `loadById`."
          );
        }
        return BaseModel.apiConfig.actions.load.apply(this, [params, vuexOrmOptions]);
      },
      async loadAll(params, options = { forceFetch: false }) {
        if (process.env.NODE_ENV === "development" && !User.getCurrent().isETCLD) {
          console.warn(
            "Attention, `Ebe.api().loadAll()` est a éviter pour charger les EBE de l'utilisateur. Prévilégier `loadCurrent`/`getCurrent` ou `loadById`."
          );
        }
        return BaseModel.apiConfig.actions.loadAll.apply(this, [params, options]);
      },
      async loadCurrent(params) {
        const user = User.getCurrent();
        const ebeId = user.ebes.length === 1 ? user.ebes[0] : null;
        return await this.loadById(ebeId, params);
      },
    },
  };
}

export class EbePublic extends Ebe {
  static entity = "ebe_public";
  static resourceUrl = "/ebe/list_public/";

  getCles() {
    return ClePublic.query().whereIdIn(this.cles).orderBy("nom").get();
  }

  getDepartements() {
    const cles = this.getCles();
    const departementIds = cles.flatMap(({ departements }) => departements);
    return DepartementEntitePublic.query().whereIdIn(departementIds).orderBy("nom").all();
  }

  get relatedEntities() {
    const cles = this.getCles();
    const departements = this.getDepartements();

    return [...cles, ...departements];
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      load(params, vuexOrmOptions) {
        // Override to remove the load without scope warning
        return BaseModel.apiConfig.actions.load.apply(this, [params, vuexOrmOptions]);
      },
      async loadAll(params, options = { forceFetch: false }) {
        // Override to remove the load without scope warning
        return BaseModel.apiConfig.actions.loadAll.apply(this, [params, options]);
      },
    },
  };
}
