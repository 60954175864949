import EbePrevisionEtp from "@/models/financement/previsionEtp/EbePrevisionEtp";

export default class EbePrevisionEtpAnnee extends EbePrevisionEtp {
  static entity = "ebe_prevision_etp_annee";
  static resourceUrl = "/financement/ebe_prevision_etp_annee/";

  static fields() {
    return {
      id: this.uid(),
      ebe: this.attr(),
      annee: this.attr(),
      status_log: this.attr(),
      auto_close_datetime: this.attr(),
      opening_reason: this.attr(),
    };
  }

  async loadSummary() {
    return await EbePrevisionEtpAnnee.api().loadSummary(this.id);
  }

  static apiConfig = {
    actions: {
      ...EbePrevisionEtp.apiConfig.actions,
      async validate(id) {
        return await this.post(`${this.model.resourceUrl}${id}/validate/`);
      },
      async loadOpenedPrevisions(params = {}) {
        const previsions = await this.load({
          ebe: params.ebe,
          ordering: "-annee",
          last_status_log: `${EbePrevisionEtpAnnee.STATUS_A_COMPLETER},${EbePrevisionEtpAnnee.STATUS_A_VALIDER}`,
        });
        return previsions.entities[this.model.entity];
      },
      async requestOpen(params = {}) {
        return await this.post(`${this.model.resourceUrl}request_open/`, params);
      },
      async loadAvailableYears() {
        return this.get(`${this.model.resourceUrl}available_years/`);
      },
      async loadSummary(id) {
        return this.get(`${this.model.resourceUrl}${id}/summary/`);
      },
    },
  };
}
