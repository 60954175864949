import BaseModel from "@/models/BaseModel";

export default class DocumentTag extends BaseModel {
  static entity = "document_tag";
  static resourceUrl = "/documents/tags/";

  static fields() {
    return {
      id: this.uid(),
      name: this.attr(),
      document_count: this.attr(),
    };
  }
}
