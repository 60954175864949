const AnnuaireEbeActivite = () => import("@/views/annuaire/AnnuaireEbeActivite.vue");
const ContactListPartage = () => import("@/views/annuaire/ContactListPartage.vue");
const SimpleContainer = () => import("@/views/SimpleContainer.vue");

const routes = [
  {
    path: "/annuaire",
    component: SimpleContainer,
    name: "annuaire",
    meta: {
      title: "Annuaires",
      icon: "mdi-account-box-multiple-outline",
      displayInMenu: true,
      disabled: true,
    },
    children: [
      {
        path: "/annuaire/partage",
        component: ContactListPartage,
        name: "annuaire-partage",
        meta: {
          title: "Annuaire partagé",
          displayInMenu: true,
        },
      },
      {
        path: "/annuaire/ebe-activite",
        component: AnnuaireEbeActivite,
        name: "annuaire-ebe-activite",
        meta: {
          title: "Annuaire des activités des EBE",
          displayInMenu: true,
        },
      },
    ],
  },
];

export default routes;
