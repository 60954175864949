<template>
  <v-alert
    :type="isPrimary ? null : variant"
    :color="isPrimary ? 'primary' : muted ? 'grey darken-2' : null"
    :border="inTable ? null : 'left'"
    :icon="icon ?? defaultIconMap[variant]"
    text
    :dense="!banner"
  >
    <div v-if="title" :class="`${banner ? 'text-h6' : 'font-weight-bold'}`">{{ title }}</div>
    <slot name="title" />
    <div :class="`text-justify ${contentClass ?? ''}`">
      <slot />
    </div>
    <div v-if="hasActionsSlot" :class="`d-flex flex-wrap gap-2 ${banner ? 'mt-4' : 'mt-2'}`">
      <slot name="actions" />
    </div>
  </v-alert>
</template>

<script>
const defaultIconMap = {
  primary: false,
  info: "mdi-information-outline",
  success: "mdi-check-circle-outline",
  warning: "mdi-alert-outline",
  error: "mdi-close-circle-outline",
  help: "mdi-chat-question-outline",
};
</script>

<script setup>
import { computed, useSlots } from "vue";

/*
Utilisation de ce composant

Alerte info (par défaut) => <AlertCard>...</AlertCard>
Alerte avec titre => <AlertCard title="Un nouveau titre">...</AlertCard>
Alerte warning => <AlertCard variant="warning">...</AlertCard>
Alerte avec icône personnalisée => <AlertCard icon="mdi-eye">...</AlertCard>
Alerte sans icône => <AlertCard :icon="false">...</AlertCard>
Alerte avec actions =>
 <AlertCard>
   <template #actions>
     <v-btn color="...">...</v-btn>
     <v-btn color="...">...</v-btn>
     <v-btn color="...">...</v-btn>
   </template>
 </AlertCard>
Alerte en bannière => <AlertCard banner title="Un nouveau titre">...</AlertCard>
Alerte moins importante => <AlertCard muted title="Une ancienne alerte">...</AlertCard>
*/

const slots = useSlots();

const props = defineProps({
  title: String,
  icon: {
    type: [Boolean, String],
    default: null,
  },
  variant: {
    type: String,
    default: "info",
    validator: (value) => Object.keys(defaultIconMap).includes(value),
  },
  banner: Boolean,
  contentClass: String,
  inTable: Boolean,
  muted: Boolean,
});

const isPrimary = props.variant === "primary";

const hasActionsSlot = computed(() => slots.actions && slots.actions());
</script>

<style lang="scss" scoped>
.v-alert::v-deep .v-alert__icon {
  margin-right: 10px;
}

.v-alert.warning--text::v-deep {
  color: var(--v-secondary-high-contrast-base) !important;

  &::before {
    background: var(--v-secondary-base);
  }

  & .v-alert__wrapper > .v-icon {
    color: var(--v-secondary-high-contrast-base) !important;
  }

  & .v-alert__border {
    color: var(--v-secondary-base);
  }
}

.v-alert.help--text::v-deep {
  color: var(--v-blue-high-contrast-base) !important; // blue--text text--darken-3

  background: #e3f2fd !important; // blue lighten-5

  &::before {
    opacity: 0;
  }

  & .v-alert__wrapper > .v-icon {
    color: var(--v-blue-high-contrast-base) !important; // blue--text text--darken-3
  }

  & .v-alert__border {
    color: var(--v-blue-high-contrast-base) !important; // blue--text text--darken-3
  }
}
</style>
