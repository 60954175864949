export const formatBytes = (bytes, decimals = 2, displaySI) => {
  if (bytes === 0) return "0 octets";

  const k = 1024;
  const sizesIEC = ["octets", "Kio", "Mio", "Gio", "Tio"];
  const sizesSI = ["octets", "Ko", "Mo", "Go", "To"];
  const sizes = displaySI ? sizesSI : sizesIEC;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`;
};
