import BaseModel from "@/models/BaseModel.js";

// This is an abstract class, not a real model

export default class EbePrevisionEtp extends BaseModel {
  static PERMISSION = Object.freeze({
    READ: "financement:ebeprevisionetp_read",
    UPDATE: "financement:ebeprevisionetp_update",
    VALIDATE: "financement:ebeprevisionetp_validate",
    MANAGE: "financement:ebeprevisionetp_manage",
  });

  static STATUS_A_COMPLETER = "A_COMPLETER";
  static STATUS_A_VALIDER = "A_VALIDER";
  static STATUS_EBE_VALIDE = "EBE_VALIDE";
  static STATUS_FERME_NON_VALIDE = "FERME_NON_VALIDE";
  static STATUS_REOUVERTURE_DEMANDEE = "REOUVERTURE_DEMANDEE";

  static STATUSES = [
    { text: "À compléter par l'EBE", value: EbePrevisionEtp.STATUS_A_COMPLETER, color: "orange", icon: "mdi-pen" },
    { text: "À valider par l'EBE", value: EbePrevisionEtp.STATUS_A_VALIDER, color: "orange lighten-2", icon: "mdi-progress-check" },
    { text: "Validé par l'EBE", value: EbePrevisionEtp.STATUS_EBE_VALIDE, color: "green", icon: "mdi-check" },
    {
      text: "Saisie fermée, non validée par l'EBE",
      value: EbePrevisionEtp.STATUS_FERME_NON_VALIDE,
      color: "grey",
      icon: "mdi-timer-lock-outline",
    },
    {
      text: "Réouverture demandée par l'EBE",
      value: EbePrevisionEtp.STATUS_REOUVERTURE_DEMANDEE,
      color: "blue-grey",
      icon: "mdi-timer-lock-outline",
    },
  ];

  get status() {
    const unknownStatus = { text: "Statut inconnu", color: "red" };
    const lastStatus = this.status_log?.[0];

    return lastStatus
      ? {
          ...lastStatus,
          ...(EbePrevisionEtp.STATUSES.find((s) => s.value === lastStatus.value) || unknownStatus),
        }
      : unknownStatus;
  }

  get lastEbeValideStatus() {
    return this.status_log?.find((status) => status.value === EbePrevisionEtp.STATUS_EBE_VALIDE) || {};
  }

  get validated_date() {
    return this.lastEbeValideStatus.datetime;
  }

  get validated_by() {
    if (!this.lastEbeValideStatus || !this.lastEbeValideStatus.user) {
      return "";
    }
    return `${this.lastEbeValideStatus?.user?.first_name} ${this.lastEbeValideStatus?.user?.last_name}`;
  }

  get isReadonly() {
    return this.status.value !== EbePrevisionEtp.STATUS_A_COMPLETER && this.status.value !== EbePrevisionEtp.STATUS_A_VALIDER;
  }

  static OPENING_REASON_NOT_OPEN = "NOT_OPEN";
  static OPENING_REASON_AUTO_ANNUAL = "AUTO_ANNUAL";
  static OPENING_REASON_AUTO_QUARTERLY = "AUTO_QUARTERLY";
  static OPENING_REASON_AUTO_INITIAL = "AUTO_INITIAL";
  static OPENING_REASON_MANUAL = "MANUAL";

  static OPENING_REASONS = [
    { text: "Pas ouvert", value: EbePrevisionEtp.OPENING_REASON_NOT_OPEN, color: "grey", icon: "mdi-calendar-lock" },
    { text: "annuelle", value: EbePrevisionEtp.OPENING_REASON_AUTO_ANNUAL, color: "grey", icon: "mdi-calendar" },
    { text: "trimestrielle", value: EbePrevisionEtp.OPENING_REASON_AUTO_QUARTERLY, color: "grey", icon: "mdi-calendar-month-outline" },
    { text: "initiale", value: EbePrevisionEtp.OPENING_REASON_AUTO_INITIAL, color: "grey", icon: "mdi-calendar-blank" },
    { text: "Manuelle", value: EbePrevisionEtp.OPENING_REASON_MANUAL, color: "grey", icon: "mdi-calendar-cursor" },
  ];

  static getOpeningReason(reasonValue) {
    return EbePrevisionEtp.OPENING_REASONS.find((reason) => reason.value === reasonValue);
  }

  get openingReason() {
    return EbePrevisionEtp.getOpeningReason(this.opening_reason);
  }
}
