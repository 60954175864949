export default {
  state: {
    modals: [Object],
    lastId: 0,
  },
  actions: {
    addModal(context, params) {
      return new Promise((resolve, reject) => {
        context.commit("ADD_MODAL", {
          title: params.title,
          message: params.message,
          messages: params.messages,
          type: params.type,
          cancelText: params.cancelText,
          confirmText: params.confirmText,
          actionPromise: params.actionPromise,
          confirmLoading: false,
          persistent: params.persistent ?? true,
          open: true,
          resolve: resolve,
          reject: reject,
        });
      });
    },
    updateModal(context, { modal, changes }) {
      context.commit("UPDATE_MODAL", { modal: modal, changes });
    },
    closeModal(context, modal) {
      context.commit("CLOSE_MODAL", { modal: modal });
    },
  },
  mutations: {
    ADD_MODAL(state, params) {
      state.lastId++;
      params.id = state.lastId;

      let existingError = state.modals.find(
        (modal) =>
          params.title === modal.title &&
          params.message === modal.message &&
          (params.messages === modal.messages || JSON.stringify(params.messages.sort()) === JSON.stringify(modal.messages.sort())) &&
          params.type === modal.type
      );
      if (existingError) return;

      state.modals.push(params);
    },
    UPDATE_MODAL(state, params) {
      let modal = state.modals.find((modal) => modal.id == params.modal.id);
      if (!modal) return;
      Object.assign(modal, params.changes);
    },
    CLOSE_MODAL(state, params) {
      let modalIndex = state.modals.findIndex((modal) => modal.id == params?.modal.id);
      if (modalIndex < 0) return;

      state.modals.splice(modalIndex, 1);
    },
  },
  namespaced: true,
};
