import { downloadFile } from "@/helpers/ApiRequest";
import BaseModel from "@/models/BaseModel";

export default class SuiviTerritoireMeteoTerritoire extends BaseModel {
  static entity = "suivi_territoire_meteo_territoire";
  static resourceUrl = "/suivi_territoire/meteo_territoire/";

  static apiDateKey = "date_meteo";

  static fields() {
    return {
      id: this.uid(),
      entite: this.attr(),
      cle: this.attr(),
      ebe: this.attr(),
      date_meteo: this.attr(),
      type_meteo: this.attr(),
      commentaires: this.attr(),
      preconisations: this.attr(),
      synthese: this.attr(),
      created_datetime: this.attr(),
      updated_datetime: this.attr(),
      created_user: this.attr(),
      updated_user: this.attr(),
    };
  }

  static PERMISSION = Object.freeze({
    READ: "suivi_territoire:meteoterritoire_read",
    MANAGE_CLE_EBE: "suivi_territoire:meteoterritoire_manage",
    MANAGE_DEPARTEMENT: "suivi_territoire:meteoterritoire_departement_manage",
    EXPORT: "suivi_territoire:meteoterritoire_export",
  });

  static TYPE = Object.freeze({
    CLASSIQUE: "CLASSIQUE",
    ADAPTE: "ADAPTE",
    RENFORCE: "RENFORCE",
    ALERTE: "ALERTE",
    NONE: "NONE",
  });

  static TYPES = Object.freeze([
    {
      text: "Suivi classique",
      value: SuiviTerritoireMeteoTerritoire.TYPE.CLASSIQUE,
      color: "success-bg",
      textColor: "success--text",
      icon: "mdi-weather-sunny",
      iconColor: "success",
    },
    {
      text: "Suivi adapté",
      value: SuiviTerritoireMeteoTerritoire.TYPE.ADAPTE,
      color: "yellow lighten-4",
      textColor: "secondary-high-contrast--text",
      icon: "mdi-weather-partly-cloudy",
      iconColor: "secondary-high-contrast",
    },
    {
      text: "Suivi renforcé",
      value: SuiviTerritoireMeteoTerritoire.TYPE.RENFORCE,
      color: "warning-bg",
      textColor: "secondary-high-contrast--text",
      icon: "mdi-weather-pouring",
      iconColor: "secondary-high-contrast",
    },
    {
      text: "Alerte",
      value: SuiviTerritoireMeteoTerritoire.TYPE.ALERTE,
      color: "error-bg",
      textColor: "error--text",
      icon: "mdi-weather-lightning",
      iconColor: "error",
    },
    {
      text: "Aucune météo",
      value: SuiviTerritoireMeteoTerritoire.TYPE.NONE,
      color: "grey lighten-4",
      textColor: "grey--text text--darken-2",
      icon: "mdi-weather-sunny-off",
      iconColor: "grey darken-2",
    },
  ]);
  static TYPES_DEPARTEMENT = Object.freeze(
    SuiviTerritoireMeteoTerritoire.TYPES.filter(({ value }) => value !== SuiviTerritoireMeteoTerritoire.TYPE.RENFORCE)
  );

  static USABLE_TYPES = Object.freeze(SuiviTerritoireMeteoTerritoire.TYPES.filter(({ value }) => value !== SuiviTerritoireMeteoTerritoire.TYPE.NONE));
  static USABLE_TYPES_DEPARTEMENT = Object.freeze(
    SuiviTerritoireMeteoTerritoire.TYPES_DEPARTEMENT.filter(({ value }) => value !== SuiviTerritoireMeteoTerritoire.TYPE.NONE)
  );

  get type() {
    return SuiviTerritoireMeteoTerritoire.getType(this.type_meteo);
  }

  static getType(type) {
    const validType = SuiviTerritoireMeteoTerritoire.TYPES.find((s) => s.value === type);
    return validType || SuiviTerritoireMeteoTerritoire.TYPES.find((s) => s.value === SuiviTerritoireMeteoTerritoire.TYPE.NONE);
  }

  static normalize(model) {
    return {
      id: model.id,
      key: `meteo-${model.id}`,
      date: model.date_meteo,
      user: model.created_user ? `${model.created_user.first_name} ${model.created_user.last_name}` : "",
      description: model.synthese,
      meteoType: model.type,
      category: "meteos",
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async loadAndNormalize(params) {
        const [rawResults, count] = await this.loadWithResults(params);

        const results = (rawResults || []).map(this.model.normalize);
        return { results, count };
      },
      async loadLatest() {
        const result = await this.get(`${this.model.resourceUrl}latest/`);
        return result.entities?.[this.model.entity];
      },
      async exportXlsx(params) {
        const urlParams = new URLSearchParams(params);
        const url = `${process.env.VUE_APP_API_URL}${SuiviTerritoireMeteoTerritoire.resourceUrl}export/?${urlParams.toString()}`;
        await downloadFile(url);
      },
    },
  };
}
