import BaseModel from "@/models/BaseModel";

import { formatDatetime } from "@/helpers/dates";

import BilanGens from "./BilanGens";

export default class BilanGensModeration extends BaseModel {
  static entity = "bilan_gens_moderation";
  static resourceUrl = "/bilan/bilan_gens_moderation/";

  static STATUS = Object.freeze({
    ACTIVE: "ACTIVE",
    VALIDATED: "VALIDE",
  });

  static fields() {
    return {
      id: this.uid(),
      bilan: this.attr(),
      commentaire_territoire: this.attr(),
      commentaire_etcld: this.attr(),
      probleme_qualite: this.attr(),
      probleme_authenticite: this.attr(),
      probleme_charte: this.attr(),
      probleme_accords_captation: this.attr(),
      qualite: this.attr(),
      thematique: this.attr(),
      citation: this.attr(),
      status_log: this.attr(),
      target_status: this.attr(),
      finalized_temoin_status: this.attr(),
      finalized_slug: this.attr(),
      finalized_nom: this.attr(),
    };
  }

  get hasProblems() {
    const fieldsProblems = [this.probleme_qualite, this.probleme_authenticite, this.probleme_charte, this.probleme_accords_captation];
    return fieldsProblems.some((probleme) => probleme == true);
  }

  get status() {
    const lastStatus = this.status_log[0].value;
    return lastStatus;
  }
  get targetStatus() {
    return BilanGens.STATUSES.filter((status) => status.value == this.target_status)[0];
  }
  get title() {
    const date = this.status_log[0].datetime;
    const moderatorFirstName = this.status_log[0].user.first_name;
    const moderatorLastName = this.status_log[0].user.last_name;
    const status = BilanGens.STATUSES.filter((status) => status.value == this.target_status)[0];
    return `Modération du ${formatDatetime(date)} par ${moderatorFirstName} ${moderatorLastName} au statut ${status.text.toLowerCase()}`;
  }
  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      validate(id) {
        return this.post(`${this.model.resourceUrl}${id}/validate/`, {});
      },
    },
  };
}
