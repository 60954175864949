<template>
  <div v-if="level !== null">
    <template v-for="route in updatedRoutes">
      <v-list-item
        v-if="!route.children?.length"
        :key="route.path"
        :to="route.path"
        :class="{ 'fix-active-list-item': isFirstLevel }"
        :color="route.notifications.length > 0 ? 'secondary' : 'primary'"
      >
        <v-list-item-title :title="route.meta.title" :class="{ 'ml-6': level === 1, 'ml-2': level === 2 }">
          <v-icon class="pe-2">{{ route.meta.icon }}</v-icon>
          {{ route.meta.getDynamicTitle?.() ?? route.meta.title }}
        </v-list-item-title>
        <BaseTooltip v-if="route.notifications.length > 0" color="warning">
          <div v-for="notification in route.notifications" :key="notification.id">
            {{ notification.title }}
          </div>
          <template #activator>
            <v-icon color="secondary" class="notification">mdi-circle-medium</v-icon>
          </template>
        </BaseTooltip>
      </v-list-item>
      <v-list-group
        v-else
        :group="route.meta?.menuGroup || route.path"
        :key="`${route.path}-children`"
        :class="{ 'mb-1': isFirstLevel }"
        :sub-group="!isFirstLevel"
        :no-action="!isFirstLevel"
        :color="route.notifications.length > 0 ? 'secondary' : 'primary'"
      >
        <template #activator>
          <v-list-item-content>
            <v-list-item-title :title="route.meta.title" :class="{ 'ml-0': !isFirstLevel }">
              <v-icon class="pe-2">{{ route.meta.icon }}</v-icon>
              {{ route.meta.title }}
            </v-list-item-title>
          </v-list-item-content>
          <BaseTooltip v-if="route.notifications.length > 0" color="warning">
            <div v-for="notification in route.notifications" :key="notification.id">
              {{ notification.title }}
            </div>
            <template #activator>
              <v-icon color="secondary" class="notification">mdi-circle-medium</v-icon>
            </template>
          </BaseTooltip>
        </template>
        <AppMenuChild :routes="route.children" :level="isFirstLevel ? 1 : level === 1 ? 2 : null" />
      </v-list-group>
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";

import AppMenuChild from "@/components/utils/AppMenu.vue";
import BaseTooltip from "@/components/utils/BaseTooltip.vue";
import Notification from "@/models/Notification";

const props = defineProps({
  routes: Array,
  level: {
    type: Number,
    default: 0,
  },
});

const notifications = computed(() => Notification.getAPITechnicalChannel());
const isFirstLevel = computed(() => props.level === 0);
const updatedRoutes = computed(() => props.routes.map(updateRoute));

const updateRoute = (route) => {
  const notifications = getRouteNotifications(route);
  const children = (route.children ?? []).map(updateRoute);
  return {
    ...route,
    children,
    notifications: [notifications, children.map((child) => child.notifications)].flat(Infinity),
  };
};

const getRouteNotifications = (route) => {
  return notifications.value.filter((notification) => route.meta?.notificationTriggers?.includes(notification.identifier));
};
</script>
<style lang="scss" scoped>
.fix-active-list-item.v-list-item--active {
  color: var(--v-primary-base);
}

::v-deep .v-list-item:has(.notification) {
  & .v-list-item__icon {
    min-width: 0 !important;
  }

  // Fix for inactive groups with notifications not having the icons coloured
  &:not(.v-list-item--active) * {
    color: var(--v-secondary-base) !important;
  }
}
</style>
