import BaseModel from "@/models/BaseModel.js";
import Ebe from "@/models/Ebe.js";
import DepartementEntite from "@/models/notrexp/DepartementEntite";
import GroupEntite from "@/models/notrexp/GroupEntite";
import User from "@/models/User";

export default class Cle extends BaseModel {
  static entity = "cle";
  static resourceUrl = "/cle/";

  static TITLE = Object.freeze({
    MADAME: "MADAME",
    MONSIEUR: "MONSIEUR",
    NON_COMMUNIQUE: "NON_COMMUNIQUE",
  });

  static TITLE_ITEMS = Object.freeze([
    { text: "Madame", value: Cle.TITLE.MADAME },
    { text: "Monsieur", value: Cle.TITLE.MONSIEUR },
    { text: "Non communiqué", value: Cle.TITLE.NON_COMMUNIQUE },
  ]);

  static fields() {
    return {
      id: this.uid(),
      nom: this.string(""),
      created_datetime: this.attr(),
      // included in the createCle
      utilisateur: this.attr(),
      // included in the extra_infos
      date_arrete_habilitation: this.attr(),
      epci: this.attr([]),
      slug: this.attr(),
      villes: this.attr([]),
      adresse: this.attr(),
      presidents: this.attr([]),
      departements: this.attr([]),
      administrateur_referent: this.attr(),
      // included in the suivi_infos
      staffsuivicle_users: this.attr([]),
    };
  }

  static PERMISSION = Object.freeze({
    MANAGE: "notrexp:cle_manage",
    UPDATE: "notrexp:cle_update",
    UPDATE_ADMIN_REF: "notrexp:cle_update_admin_ref",
  });

  get entityType() {
    return "CLE";
  }

  getEbes() {
    return Ebe.query()
      .where((ebe) => ebe.cles.includes(this.id))
      .orderBy("nom")
      .get();
  }

  getDepartements() {
    return DepartementEntite.query().whereIdIn(this.departements).orderBy("nom").all();
  }

  getGroups() {
    return GroupEntite.query()
      .where((group) => group.entites.includes(this.id))
      .orderBy("nom")
      .get();
  }

  get relatedEntities() {
    const ebes = this.getEbes();
    const departements = this.getDepartements();

    return [...ebes, ...departements];
  }

  get color() {
    return Cle.getColor();
  }
  get icon() {
    return Cle.getIcon();
  }

  static getCurrent() {
    const user = User.getCurrent();
    const cleId = user.cles.length === 1 ? user.cles[0] : null;
    return this.query().whereId(cleId).first();
  }

  static getColor() {
    return {
      text: "info--text",
      background: "info-bg",
      activeBackground: "info",
    };
  }

  static getIcon() {
    return process.env.VUE_APP_DISABLE_NEW_ICONS ? "mdi-town-hall" : "$cle-icon";
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      load(params, vuexOrmOptions) {
        if (process.env.NODE_ENV === "development" && !User.getCurrent().isETCLD && !params) {
          console.warn(
            "Attention, `Cle.api().load()` sans contexte est a éviter pour charger les CLE de l'utilisateur. Prévilégier `loadCurrent`/`getCurrent` ou `loadById`."
          );
        }
        return BaseModel.apiConfig.actions.load.apply(this, [params, vuexOrmOptions]);
      },
      async loadAll(params, options = { forceFetch: false }) {
        if (process.env.NODE_ENV === "development" && !User.getCurrent().isETCLD) {
          console.warn(
            "Attention, `Cle.api().loadAll()` est a éviter pour charger les CLE de l'utilisateur. Prévilégier `loadCurrent`/`getCurrent` ou `loadById`."
          );
        }
        return BaseModel.apiConfig.actions.loadAll.apply(this, [params, options]);
      },
      async loadCurrent(params) {
        const user = User.getCurrent();
        const cleId = user.cles.length === 1 ? user.cles[0] : null;

        return await this.loadById(cleId, params);
      },
    },
  };
}

export class ClePublic extends Cle {
  static entity = "cle_public";
  static resourceUrl = "/cle/list_public/";

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      load(params, vuexOrmOptions) {
        // Override to remove the load without scope warning
        return BaseModel.apiConfig.actions.load.apply(this, [params, vuexOrmOptions]);
      },
      async loadAll(params, options = { forceFetch: false }) {
        // Override to remove the load without scope warning
        return BaseModel.apiConfig.actions.loadAll.apply(this, [params, options]);
      },
    },
  };
}
