const ContactProfile = () => import("@/views/annuaire/ContactProfile.vue");
const Login = () => import("@/views/Login.vue");
const ChangePassword = () => import("@/views/ChangePassword.vue");
const ForgotPassword = () => import("@/views/ForgotPassword.vue");
const NoAccount = () => import("@/views/NoAccount.vue");

const routes = [
  {
    path: "/profile",
    component: ContactProfile,
    name: "profile",
    meta: {
      title: "Mon profil",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Login",
      icon: "",
      hideNavigations: true,
      requiresLogin: false,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      title: "Mot de passe oublié",
      icon: "",
      hideNavigations: true,
      requiresLogin: false,
    },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
    meta: {
      title: "Changement de mot de passe",
      icon: "",
      hideNavigations: true,
      requiresLogin: false,
    },
  },
  {
    path: "/no-account",
    name: "no-account",
    component: NoAccount,
    meta: {
      title: "Vous n'avez pas de compte NotreXP ?",
      icon: "",
      hideNavigations: true,
      requiresLogin: false,
    },
  },
];

export default [...routes];
