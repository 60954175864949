import { toDistinct } from "@/helpers/array";

import BaseModel from "@/models/BaseModel";
import Cle from "@/models/Cle";
import GroupEntite from "@/models/notrexp/GroupEntite";

export default class DepartementEntite extends BaseModel {
  static entity = "departement_entite";
  static resourceUrl = "/notrexp/departement_entite/";

  static fields() {
    return {
      id: this.uid(),
      nom: this.attr(),
      code_insee: this.attr(),
      annee_conventionnement: this.attr(),
      nombre_versements: this.attr(),
      modalite_versement: this.attr(),
      reprise_excedent: this.attr(),
      prise_en_charge_complementaire: this.attr(),
      prise_en_charge_complementaire_taux: this.attr(),
      modalites_fonctionnement: this.attr(),
      // included in extra_infos
      staffsuividepartement_users: this.attr([]),
    };
  }

  static PERMISSION = Object.freeze({
    READ: "notrexp:departemententite_read",
    UPDATE: "notrexp:departemententite_update",
  });

  get entityType() {
    return "Département";
  }

  get fullName() {
    return `${this.nom} (${this.code_insee})`;
  }

  getCles() {
    return Cle.query()
      .where((cle) => {
        return cle.departements.includes(this.id);
      })
      .orderBy("nom")
      .all();
  }

  getEbes() {
    const cles = this.getCles();
    return cles.flatMap((cle) => cle.getEbes());
  }

  getGroups() {
    return GroupEntite.query()
      .where((group) => group.entites.includes(this.id))
      .orderBy("nom")
      .get();
  }

  get relatedEntities() {
    const cles = this.getCles();
    const ebes = toDistinct(this.getEbes(), "id");

    return [...cles, ...ebes];
  }

  get color() {
    return DepartementEntite.getColor();
  }

  get icon() {
    return DepartementEntite.getIcon();
  }

  static getColor() {
    return {
      text: "secondary-high-contrast--text",
      background: "warning-bg",
      activeBackground: "secondary-high-contrast",
    };
  }

  static getIcon() {
    return "mdi-bank";
  }
}

export class DepartementEntitePublic extends DepartementEntite {
  static entity = "departement_entite_public";
  static resourceUrl = "/notrexp/departement_entite/list_public/";
}
