import Document from "@/models/documents/Document";

const Documents = () => import("@/views/documents/Documents.vue");

const routes = [
  {
    path: "/documents",
    component: Documents,
    name: "document-list",
    meta: {
      title: "Documents partagés",
      icon: "mdi-paperclip",
      displayInMenu: true,
      displayBottom: true,
      permissions: [Document.PERMISSIONS.READ],
      notificationTriggers: ["documents.new_document_technical"],
    },
  },
];

export default routes;
