import { downloadFile } from "@/helpers/ApiRequest";

import ContactProfileFonction from "@/models/annuaire/ContactProfileFonction";
import ContactProfileStructure from "@/models/annuaire/ContactProfileStructure";
import ContactProfileStructureType from "@/models/annuaire/ContactProfileStructureType";
import BaseModel from "@/models/BaseModel";
import Cle from "@/models/Cle";
import Ebe from "@/models/Ebe";
import Group from "@/models/notrexp/Group";
import User from "@/models/User";

const { TYPE } = ContactProfileStructureType;
export default class ContactProfile extends BaseModel {
  static entity = "contact_profile";
  static resourceUrl = "/annuaire/contact_profile/";

  static fields() {
    return {
      id: this.uid(),
      user: this.attr(),
      first_name: this.attr(),
      last_name: this.attr(),
      email: this.attr(),
      emails: this.attr([]),
      email_comment: this.attr(),
      phone_numbers: this.attr([]),
      adresse: this.attr(),
      adresse_liee: this.attr(),
      poste: this.attr(),
      fonction: this.attr(),
      liste_diffusion: this.attr(),
      comments: this.attr(),
      is_public: this.attr(),
      is_etcld: this.attr(),
      rgpd_consent: this.attr(),
      ebes: this.attr(),
      cles: this.attr(),
      groups: this.attr([]),
      structures: this.attr([]),
      action_log: this.attr(),
      can_be_enabled: this.attr(),
      can_be_deleted: this.attr(),

      // archive PUT action
      archived: this.attr(),
    };
  }

  static PERMISSION = Object.freeze({
    READ_PUBLIC: "annuaire:contactprofile_read_public",
    READ_PRIVATE: "annuaire:contactprofile_read_private",
    MANAGE: "annuaire:contactprofile_manage",
  });

  static TYPE = Object.freeze({
    USER: false,
    CONTACT: true,
  });

  static TYPES = Object.freeze([
    {
      text: "Contact avec accès utilisateur·ice",
      value: false,
      color: "success-bg",
      textColor: "success--text",
      icon: "mdi-account-check",
      iconColor: "success",
    },
    {
      text: "Contact",
      value: true,
      color: "grey lighten-4",
      textColor: "grey--text text--darken-2",
      icon: "mdi-account",
      iconColor: "grey",
    },
  ]);

  get lastUpdate() {
    return this.action_log?.[0] ?? null;
  }

  get fullName() {
    return `${this.last_name} ${this.first_name}`;
  }

  get structureInstances() {
    return this.structures
      .map((structure) => new ContactProfileStructure(structure))
      .sort((a, b) => {
        if (this.isCLEUser && this.cles.includes(a.structure_cle) && !this.cles.includes(b.structure_cle)) return -1;
        if (this.isCLEUser && !this.cles.includes(a.structure_cle) && this.cles.includes(b.structure_cle)) return 1;
        if (this.isEBEUser && this.ebes.includes(a.structure_ebe) && !this.ebes.includes(b.structure_ebe)) return -1;
        if (this.isEBEUser && !this.ebes.includes(a.structure_ebe) && this.ebes.includes(b.structure_ebe)) return 1;
        return 0;
      });
  }

  getStructuresFromEntities(ebeModel = Ebe, cleModel = Cle) {
    const structures = [];

    if (this.isETCLDUser) {
      const structureType = ContactProfileStructureType.query()
        .where(({ nom, type_donnee }) => nom === TYPE.ETCLD && type_donnee === TYPE.NUL)
        .first();
      const structure = new ContactProfileStructure({
        structure_type: structureType.id,
        name: "ETCLD",
      });
      structures.push(structure);
    }

    if (this.cles) {
      const cles = cleModel.findIn(this.cles);
      cles.forEach((cle) => {
        const structureType = ContactProfileStructureType.query().where("type_donnee", TYPE.CLE).first();
        const structure = new ContactProfileStructure({
          structure_type: structureType.id,
          structure_cle: cle.id,
          name: cle.nom,
        });
        structures.push(structure);
      });
    }

    if (this.ebes) {
      const ebes = ebeModel.findIn(this.ebes);
      ebes.forEach((ebe) => {
        const structureType = ContactProfileStructureType.query().where("type_donnee", TYPE.EBE).first();
        const structure = new ContactProfileStructure({
          structure_type: structureType.id,
          structure_ebe: ebe.id,
          name: ebe.nom,
        });
        structures.push(structure);
      });
    }

    return structures;
  }

  get entities() {
    return [...Cle.findIn(this.cles), ...Ebe.findIn(this.ebes)];
  }

  get userInstance() {
    return User.find(this.user);
  }

  get groupInstances() {
    return Group.findIn(this.groups);
  }

  get isUser() {
    return !!this.user;
  }

  get isETCLDUser() {
    return this.is_etcld || this.groups.some((group) => Group.ETCLD_GROUPS.includes(group));
  }

  get isCLEUser() {
    return this.groups.some((group) => Group.CLE_GROUPS.includes(group));
  }

  get isEBEUser() {
    return this.groups.some((group) => Group.EBE_GROUPS.includes(group));
  }

  get hasValidAddress() {
    return this.adresse?.ville || this.adresse?.code_postal;
  }

  getPlainTextAddress(isMultiline) {
    const { adresse, adresse_complementaire, code_postal, ville } = this.adresse;
    const parts = [];
    const villeParts = [];
    if (adresse) parts.push(adresse);
    if (adresse_complementaire) parts.push(adresse_complementaire);
    if (code_postal) villeParts.push(code_postal);
    if (ville) villeParts.push(ville);
    parts.push(villeParts.join(" "));
    return parts.join(isMultiline ? "\n" : ", ");
  }

  get fonctionTitle() {
    return ContactProfileFonction.find(this.fonction)?.titre;
  }

  get availableFonctionInstances() {
    if (!this.user) return ContactProfileFonction.getContactFonctions();
    const user = User.find(this.user);
    if (user.isETCLD) return ContactProfileFonction.getETCLDFonctions();
    if (user.isCLE) return ContactProfileFonction.getCLEFonctions();
    if (user.isEBE) return ContactProfileFonction.getEBEFonctions();
    return [];
  }

  hasAccessTo(structure) {
    if (this.isETCLDUser) {
      const structureType = ContactProfileStructureType.find(structure.structure_type);
      return structureType.type_donnee === TYPE.NUL && structureType.nom === TYPE.ETCLD;
    }
    if (this.isCLEUser && this.cles.includes(structure.structure_cle)) return true;
    if (this.isEBEUser && this.ebes.includes(structure.structure_ebe)) return true;
    return false;
  }

  async enable(data) {
    return User.api().enable(this.id, data);
  }

  async delete() {
    return ContactProfile.api().destroy(this);
  }

  async archive() {
    const updatedContactProfile = new ContactProfile({
      ...this,
      adresse: this.adresse ?? {},
      archived: true,
    });
    return ContactProfile.api().save(updatedContactProfile);
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      loadPublic(params) {
        return this.get(`${this.model.resourceUrl}public`, { params });
      },
      async search(search) {
        const response = await this.get(`${this.model.resourceUrl}`, { params: { search } });
        return response.entities[this.model.entity];
      },
      async searchSimilar(data) {
        const {
          response: { data: items = [] },
        } = await this.post(`${this.model.resourceUrl}search_similar/`, data);
        return items.map((item) => new ContactProfile(item));
      },
      async exportXlsx(params) {
        const urlParams = new URLSearchParams(params);
        const url = `${process.env.VUE_APP_API_URL}${this.model.resourceUrl}export_xlsx/?${urlParams.toString()}`;
        await downloadFile(url);
      },
    },
  };
}
