<template>
  <v-app>
    <v-navigation-drawer app v-if="!$route.meta.hideNavigations" v-model="navigationOpen">
      <template #prepend>
        <v-app-bar flat>
          <v-app-bar-nav-icon @click.stop="navigationOpen = !navigationOpen"></v-app-bar-nav-icon>
          <img :src="require('@/assets/etcld_logo.svg')" height="100%" />
        </v-app-bar>
      </template>

      <v-list dense nav>
        <AppMenu :routes="routes" />
      </v-list>
      <template #append>
        <v-list dense nav>
          <AppMenu :routes="documentRoutes" />
          <v-list-item v-if="ressourceUrl" :href="ressourceUrl" class="mt-1">
            <v-list-item-title>
              <v-icon class="pe-2">mdi-bookshelf</v-icon>
              Ressources
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-alert class="text-center mb-0" color="green" dark icon="mdi-chat-question-outline" prominent text>
          <strong>Un bug ou une question ?</strong><br />
          Envoyez un mail à
          <strong><LinkSupport style="color: #4caf50" /></strong>
        </v-alert>
        <v-divider v-if="adminUrl" />
        <div class="pa-2" v-if="adminUrl">
          <v-btn block text color="primary" :href="adminUrl">
            <v-icon left> mdi-cog </v-icon>
            Administration
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app flat v-if="!$route.meta.hideNavigations">
      <template v-if="$vuetify.breakpoint.mobile || (!$vuetify.breakpoint.mobile && !navigationOpen)">
        <v-app-bar-nav-icon @click.stop="navigationOpen = !navigationOpen"></v-app-bar-nav-icon>
        <img :src="require('@/assets/etcld_logo.svg')" height="100%" class="pe-4" />
      </template>
      <v-toolbar-title>
        <v-breadcrumbs :items="breadcrumb" class="pa-0">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <div style="white-space: pre-wrap">{{ appTitle }}</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-flex gap-4 align-center">
        <NotificationMenu />
        <UserMenu />
      </div>
    </v-app-bar>

    <v-main class="notrexp-main">
      <v-container fill-height fluid>
        <router-view></router-view>
        <Modal />
        <store-notification />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppMenu from "@/components/utils/AppMenu.vue";
import LinkSupport from "@/components/utils/LinkSupport.vue";
import Modal from "@/components/utils/Modal.vue";
import NotificationMenu from "@/components/utils/NotificationMenu.vue";
import StoreNotification from "@/components/utils/StoreNotification.vue";
import UserMenu from "@/components/utils/UserMenu.vue";

import authenticationMixin from "@/helpers/authenticationMixin.js";

import User from "@/models/User";

export default {
  name: "App",
  mixins: [authenticationMixin],
  components: {
    Modal,
    StoreNotification,
    LinkSupport,
    AppMenu,
    NotificationMenu,
    UserMenu,
  },
  data: () => ({
    navigationOpen: true,
    ressourceUrl: process.env.VUE_APP_RESSOURCES_URL || null,
    timeoutTimer: null,
  }),
  mounted() {
    this.timeoutTimer = setInterval(() => {
      this.checkUserLoggedIn();
    }, 1000 * 60);
  },
  beforeDestroy() {
    clearInterval(this.timeoutTimer);
  },
  computed: {
    adminUrl() {
      if (this.authenticatedUser?.is_staff) {
        return `${process.env.VUE_APP_API_URL}/admin/`;
      }
      return "";
    },
    appTitle() {
      return this.$store.state.app.title || " ";
    },
    breadcrumb() {
      let breadcrumb = [];
      let paths = this.$route.path.split("/");
      paths = paths.slice(1);

      for (let i = 0; i < paths.length; i++) {
        var path = "/" + paths.slice(0, i + 1).join("/");
        let resolve = this.$router.resolve(path);

        if (!resolve.route) {
          continue;
        }

        if (!resolve.route.meta?.title) {
          continue;
        }

        breadcrumb.push({
          text: resolve.route.meta.getDynamicTitle?.() ?? resolve.route.meta.title,
          to: resolve.route.path,
          disabled: resolve.route.meta.disabled || i === paths.length - 1 || false,
          exact: true,
        });
      }

      return breadcrumb;
    },
    routes() {
      return this.filterRoutes(this.$router.options.routes.filter((route) => route.meta?.displayBottom !== true));
    },
    documentRoutes() {
      return this.filterRoutes(this.$router.options.routes.filter((route) => route.meta?.displayBottom === true));
    },
  },
  methods: {
    filterRoutes(routes) {
      return !routes
        ? []
        : routes.filter(this.shouldDisplayRoute).map((route) => ({
            ...route,
            children: route.children ? this.filterRoutes(route.children) : null,
          }));
    },
    shouldDisplayRoute(route) {
      if (!route.meta) {
        return false;
      }

      if (!route.meta.displayInMenu) {
        return false;
      }

      if (route.meta.groups == undefined && route.meta.permissions == undefined) {
        return true;
      }

      if (route.meta.groups && !route.meta.groups.some((group) => this.isInGroup(group))) {
        return false;
      }

      if (route.meta.permissions && !route.meta.permissions.some((permission) => this.hasPermission(permission))) {
        return false;
      }

      return true;
    },
    async checkUserLoggedIn() {
      if (this.$route.path.startsWith("/login")) {
        return;
      }
      try {
        await User.api().getCurrent({ handlingErrors: false, handlingAPIUnreachable: false });
      } catch (error) {
        if (error.response?.status === 401) {
          location.assign("/login?disconnected=1");
        }
      }
    },
  },
};
</script>

<style lang="scss">
/* Fix role button not clickable when disabled (ie: a text input to open a calendar dialog cc BirthdayDatePicker)*/
[role="button"]:disabled {
  cursor: default;
}
</style>
<style lang="scss" scoped>
.theme--light.v-application {
  background: #fafafa;
}
.notrexp-main {
  overflow-y: scroll;
  height: 100px;
}
</style>
