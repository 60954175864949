import AttachedS3File from "@/models/AttachedS3File";
import BaseModel from "@/models/BaseModel";
import Cle from "@/models/Cle.js";
import Ebe from "../Ebe";

export default class BilanGens extends BaseModel {
  static entity = "bilan_gens";
  static resourceUrl = "/bilan/bilan_gens/";

  static STATUS = Object.freeze({
    A_COMPLETER: "A_COMPLETER",
    A_MODERER: "A_MODERER",
    USER_A_MODERER: "USER_A_MODERER",
    A_ARBITRER: "A_ARBITRER",
    A_EDITORIALISER: "A_EDITORIALISER",
    A_DIFFUSER: "A_DIFFUSER",
    USER_A_DIFFUSER: "USER_A_DIFFUSER",
    DIFFUSE: "DIFFUSE",
    ANNULE: "ANNULE",
  });

  static ETCLD_STATUS = [
    BilanGens.STATUS.A_COMPLETER,
    BilanGens.STATUS.A_MODERER,
    BilanGens.STATUS.A_ARBITRER,
    BilanGens.STATUS.A_EDITORIALISER,
    BilanGens.STATUS.A_DIFFUSER,
    BilanGens.STATUS.DIFFUSE,
    BilanGens.STATUS.ANNULE,
  ];

  static USER_STATUS = [
    BilanGens.STATUS.A_COMPLETER,
    BilanGens.STATUS.USER_A_MODERER,
    BilanGens.STATUS.USER_A_DIFFUSER,
    BilanGens.STATUS.DIFFUSE,
    BilanGens.STATUS.ANNULE,
  ];

  static STATUSES = Object.freeze([
    {
      text: "Diffusé",
      identifier: BilanGens.STATUS.DIFFUSE,
      value: BilanGens.STATUS.DIFFUSE,
      color: "success-bg",
      textColor: "success--text",
      icon: "mdi-check-all",
      iconColor: "success",
    },
    {
      text: "À compléter",
      identifier: BilanGens.STATUS.A_COMPLETER,
      value: BilanGens.STATUS.A_COMPLETER,
      color: "grey lighten-4",
      textColor: "grey--text text--darken-2",
      icon: "mdi-pen",
      iconColor: "grey darken-2",
    },
    {
      text: "En attente de modération",
      identifier: BilanGens.STATUS.USER_A_MODERER,
      value: "A_MODERER,A_ARBITRER,A_EDITORIALISER",
      color: "warning-bg",
      textColor: "secondary-high-contrast--text",
      icon: "mdi-progress-check",
      iconColor: "secondary-high-contrast",
    },
    {
      text: "À modérer",
      identifier: BilanGens.STATUS.A_MODERER,
      value: BilanGens.STATUS.A_MODERER,
      color: "warning-bg",
      textColor: "secondary-high-contrast--text",
      icon: "mdi-progress-check",
      iconColor: "secondary-high-contrast",
    },
    {
      text: "À arbitrer",
      identifier: BilanGens.STATUS.A_ARBITRER,
      value: BilanGens.STATUS.A_ARBITRER,
      color: "primary-bg",
      textColor: "primary--text",
      icon: "mdi-scale-balance",
      iconColor: "primary",
    },
    {
      text: "À éditorialiser",
      identifier: BilanGens.STATUS.A_EDITORIALISER,
      value: BilanGens.STATUS.A_EDITORIALISER,
      color: "blue lighten-5",
      textColor: "blue--text",
      icon: "mdi-brush",
      iconColor: "blue",
    },
    {
      text: "À diffuser",
      identifier: BilanGens.STATUS.A_DIFFUSER,
      value: BilanGens.STATUS.A_DIFFUSER,
      color: "info-bg",
      textColor: "info--text",
      icon: "mdi-check",
      iconColor: "info",
    },
    {
      text: "Validé",
      identifier: BilanGens.STATUS.USER_A_DIFFUSER,
      value: BilanGens.STATUS.USER_A_DIFFUSER,
      color: "info-bg",
      textColor: "info--text",
      icon: "mdi-check",
      iconColor: "info",
    },
    {
      text: "Annulé",
      identifier: BilanGens.STATUS.ANNULE,
      value: BilanGens.STATUS.ANNULE,
      color: "error-bg",
      textColor: "error--text",
      icon: "mdi-close-circle-outline",
      iconColor: "error",
    },
  ]);

  static CONTENT_FORMAT = Object.freeze({
    TEXTE: "TEXTE",
    AUDIO: "AUDIO",
    VIDEO: "VIDEO",
    AUTRE: "AUTRE",
  });

  static CONTENT_FORMATS = Object.freeze([
    {
      text: "Texte",
      value: BilanGens.CONTENT_FORMAT.TEXTE,
    },
    {
      text: "Audio",
      value: BilanGens.CONTENT_FORMAT.AUDIO,
      icon: "mdi-volume-high",
      authorizedFileTypes: [AttachedS3File.AUDIO],
    },
    {
      text: "Vidéo",
      value: BilanGens.CONTENT_FORMAT.VIDEO,
      icon: "mdi-video",
      authorizedFileTypes: [AttachedS3File.VIDEO],
    },
    {
      text: "Autre",
      value: BilanGens.CONTENT_FORMAT.AUTRE,
      icon: "mdi-file-document",
      authorizedFileTypes: [AttachedS3File.IMAGE, AttachedS3File.PDF],
    },
  ]);

  static getContentFormat = (input) => {
    const contentFormat = BilanGens.CONTENT_FORMATS.find((format) => format.value === input);
    return contentFormat ?? { text: "Format inconnu" };
  };

  static S3_TARGET_QUESTION_CONTENT_FILE = "bilan.bilan_gens.question_content_file";
  static S3_TARGET_QUESTION_ILLUSTRATION = "bilan.bilan_gens.question_illustration";
  static S3_TARGET_ACCORDS_CAPTATION = "bilan.bilan_gens.accords_captation";

  static fields() {
    return {
      id: this.uid(),
      status_log: this.attr(),
      created_etcld: this.attr(),
      created_cle: this.attr(),
      created_ebe: this.attr(),
      temoins: this.attr([]),
      question_titre: this.attr(),
      question_date: this.attr(),
      question_format: this.attr(),
      question_content_texte: this.attr(),
      question_content_file: this.attr(),
      question_illustration: this.attr(),
      accords_captation: this.attr(),
      accord_charte: this.attr(),
      accord_rgpd: this.attr(),
      question_info_supplementaire: this.attr(),
      active_moderations: this.attr(),

      // for list serializer, will have the first name
      question_temoin_nom: this.attr(),
    };
  }

  get createdUser() {
    if (this.created_cle) {
      return { nom: this.created_cle.nom, color: Cle.getColor(), icon: Cle.getIcon() };
    }
    if (this.created_ebe) {
      return { nom: this.created_ebe.nom, color: Ebe.getColor(), icon: Ebe.getIcon() };
    }
    if (this.created_etcld) {
      return {
        nom: "ETCLD",
        color: {
          text: "blue--text text--darken-3",
          background: "blue lighten-5",
        },
        icon: "$etcld-icon",
      };
    }
    return { nom: "Inconnu" };
  }

  get etcldCurrentStatus() {
    let status = null;
    const statuses = BilanGens.STATUSES.filter((s) => BilanGens.ETCLD_STATUS.includes(s.identifier));

    if (this.status_log?.length) {
      status = statuses.find((status) => status.value.indexOf(this.status_log[0].value) != -1);
    }
    return status ?? { text: "Statut inconnu", color: "red", icon: "mdi-close" };
  }

  get userCurrentStatus() {
    let status = null;
    const statuses = BilanGens.STATUSES.filter((s) => BilanGens.USER_STATUS.includes(s.identifier));

    if (this.status_log?.length) {
      status = statuses.find((status) => status.value.indexOf(this.status_log[0].value) != -1);
    }
    return status ?? { text: "Statut inconnu", color: "red", icon: "mdi-close" };
  }

  get addedDate() {
    if (!this.status_log?.length) return null;
    return this.status_log.at(-1).datetime.split("T")[0];
  }

  get modifiedDate() {
    if (!this.status_log?.length) return null;
    return this.status_log[0].datetime.split("T")[0];
  }

  get fullTitle() {
    return `${this.question_temoin_nom ?? "(Prénom non renseigné)"} – ${this.question_titre ?? "(Titre non renseigné)"}`;
  }

  getPreviousStatus(datetime) {
    return this.status_log.find((s) => new Date(s.datetime) <= new Date(datetime));
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      validate(id) {
        return this.post(`${this.model.resourceUrl}${id}/validate/`, {});
      },
      bulkAddStatus(bilans, status) {
        const data = {
          status,
          bilan_ids: bilans.map(({ id }) => id),
        };
        return this.post(`${this.model.resourceUrl}update_status/`, data);
      },
      createVersion(id) {
        return this.post(`${this.model.resourceUrl}${id}/create_version/`, {});
      },
    },
  };
}
