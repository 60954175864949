import { VTextField } from "vuetify/lib";

const readableNumber = (value, delimiter = " ") => value?.toString().replace(/\B(?<!,\d*)(?=(\d{3})+(?!\d))/g, delimiter);

const cleanEditValue = (value, type, decimals) => {
  if (typeof value !== "string") {
    return value;
  }
  value = value.replace(/\s/g, "");

  if (type == "int") {
    value = value.replace(/[^\d-]/g, "");
  }

  if (type == "float" || type == "money") {
    value = value.replace(".", ",");
    value = value.replace(/[^\d+\-,]/g, "");
    let match = value.match(new RegExp(`([+-]?(([0-9]*)?[,]?([0-9]){0,${decimals}}))`));
    if (!match) {
      value = null;
    }
    value = match[1];
  }
  return value;
};

const displayToValue = (value, type, nullable) => {
  if (value == null || value == undefined || value == "") {
    return nullable ? null : 0;
  }

  value = value.replace(",", ".");

  if (type == "int") {
    return parseInt(value);
  }

  if (type == "float" || type == "money") {
    return parseFloat(value);
  }
  return value;
};

const valueToDisplay = (value, type, decimals, delimiter, nullable, manualEdited) => {
  if (value == null || value == undefined) {
    return "";
  }

  if (typeof value == "string") {
    value = cleanEditValue(value, type, decimals);
    value = displayToValue(value, type, nullable);
  }

  if (!manualEdited && (type == "float" || type == "money")) {
    let decimal_value = Math.pow(10, decimals);
    value = (Math.round(value * decimal_value) / decimal_value).toFixed(decimals);
  }

  value = value.toString();
  value = cleanEditValue(value, type, decimals);

  if (!manualEdited) {
    value = readableNumber(value, delimiter);
  }

  return value;
};

const formatDecimal = (value, type = "float", decimals = 2, delimiter = "\u00a0", nullable = false) => {
  return valueToDisplay(value, type, decimals, delimiter, nullable, false);
};

export default {
  extends: VTextField,

  props: {
    type: {
      type: String,
      default: "float",
      validator(val) {
        return ["int", "float", "money"].includes(val);
      },
    },
    decimals: {
      type: Number,
      default: 2,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
    delimiter: {
      type: String,
      default: " ",
    },
  },
  data() {
    return {
      manualEdited: false,
    };
  },
  computed: {
    decimalRegex() {
      let regex = `([+-]?(([0-9]*)?[,]?([0-9]){0,${this.decimals}}))`;
      return new RegExp(regex);
    },
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(value) {
        this.lazyValue = value;
        let parsedValue = this.displayToValue(this.lazyValue);
        if (!isNaN(parsedValue)) {
          this.$emit("input", parsedValue);
        }
      },
    },
  },
  methods: {
    onInput(e) {
      // Override the VTextField.onInput method
      const target = e.target;
      this.internalValue = this.cleanEditValue(target.value);

      target.value = this.internalValue;
      this.badInput = target.validity && target.validity.badInput;
      this.manualEdited = true;
    },
    valueToDisplay(value) {
      return valueToDisplay(value, this.type, this.decimals, this.delimiter, this.nullable, this.manualEdited);
    },
    displayToValue(value) {
      return displayToValue(value, this.type, this.nullable);
    },
    cleanEditValue(value) {
      return cleanEditValue(value, this.type, this.decimals);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.lazyValue = this.valueToDisplay(value);
      },
    },
  },
};

export { readableNumber, formatDecimal };
