import BaseModel from "@/models/BaseModel";
import Cle from "@/models/Cle";
import Ebe from "@/models/Ebe";
import DepartementEntite from "@/models/notrexp/DepartementEntite";

export default class ContactProfileStructureType extends BaseModel {
  static entity = "contact_profile_structure_type";
  static resourceUrl = "/annuaire/structure_type/";

  static fields() {
    return {
      id: this.uid(),
      nom: this.attr(),
      type_donnee: this.attr(),
    };
  }

  static TYPE = Object.freeze({
    ETCLD: "ETCLD", // Custom
    REGION: "REGION",
    DEPARTEMENT: "DEPARTEMENT_ENTITE",
    COMMUNE: "COMMUNE",
    EPCI: "EPCI",
    CLE: "CLE",
    EBE: "EBE",
    TEXTE: "TEXTE",
    NUL: "NUL",
  });

  static BASIC_CHIP = Object.freeze({
    icon: "mdi-city",
    color: {
      text: "grey--text text--darken-2",
      background: "grey lighten-4",
    },
  });

  static CHIP = Object.freeze({
    [ContactProfileStructureType.TYPE.ETCLD]: {
      icon: "$vuetify.icons.etcld-icon",
      color: {
        text: "blue--text text--darken-3",
        background: "blue lighten-5",
      },
    },
    [ContactProfileStructureType.TYPE.CLE]: {
      icon: Cle.getIcon(),
      color: Cle.getColor(),
    },
    [ContactProfileStructureType.TYPE.EBE]: {
      icon: Ebe.getIcon(),
      color: Ebe.getColor(),
    },
    [ContactProfileStructureType.TYPE.DEPARTEMENT]: {
      icon: DepartementEntite.getIcon(),
      color: DepartementEntite.getColor(),
    },
    [ContactProfileStructureType.TYPE.EPCI]: ContactProfileStructureType.BASIC_CHIP,
    [ContactProfileStructureType.TYPE.COMMUNE]: ContactProfileStructureType.BASIC_CHIP,
    [ContactProfileStructureType.TYPE.REGION]: ContactProfileStructureType.BASIC_CHIP,
    [ContactProfileStructureType.TYPE.TEXTE]: ContactProfileStructureType.BASIC_CHIP,
    [ContactProfileStructureType.TYPE.NUL]: ContactProfileStructureType.BASIC_CHIP,
  });

  get chip() {
    if (this.type_donnee === ContactProfileStructureType.TYPE.NUL && this.nom === ContactProfileStructureType.TYPE.ETCLD) {
      return ContactProfileStructureType.getChip(ContactProfileStructureType.TYPE.ETCLD);
    }
    return ContactProfileStructureType.getChip(this.type_donnee);
  }

  get isSuiviAvailable() {
    return [ContactProfileStructureType.TYPE.CLE, ContactProfileStructureType.TYPE.EBE, ContactProfileStructureType.TYPE.DEPARTEMENT].includes(
      this.type_donnee
    );
  }

  static getChip(type) {
    return (
      ContactProfileStructureType.CHIP[type] ?? {
        icon: "mdi-close",
        color: {
          text: "error--text",
          background: "error-bg",
        },
      }
    );
  }
}
