import useAuthentication from "@/helpers/composables/useAuthentication";

import ContactProfile from "@/models/annuaire/ContactProfile";

const ContactList = () => import("@/views/annuaire/ContactList.vue");
const ContactProfileComponent = () => import("@/views/annuaire/ContactProfile.vue");

const routes = [
  {
    path: "/gestion/utilisateurs",
    component: ContactList,
    name: "gestion-utilisateur-list",
    meta: {
      title: "Contacts et utilisateur·ice·s",
      getDynamicTitle: () => {
        const { isETCLDUser } = useAuthentication();
        return isETCLDUser.value ? "Contacts et utilisateur·ice·s" : "Gestion des utilisateur·ice·s";
      },
      icon: "mdi-account-group",
      displayInMenu: true,
      permissions: [ContactProfile.PERMISSION.READ_PRIVATE],
    },
  },
  {
    path: "/gestion/utilisateurs/:id",
    component: ContactProfileComponent,
    name: "gestion-utilisateur",
    meta: {
      permissions: [ContactProfile.PERMISSION.READ_PRIVATE],
    },
  },
];

export default routes;
