import ContactProfileStructureType from "@/models/annuaire/ContactProfileStructureType";
import BaseModel from "@/models/BaseModel";
import Cle from "@/models/Cle";
import Commune from "@/models/Commune";
import Ebe from "@/models/Ebe";
import Epci from "@/models/Epci";
import DepartementEntite from "@/models/notrexp/DepartementEntite";
import Region from "@/models/Region";

export default class ContactProfileStructure extends BaseModel {
  static entity = "contact_profile_structure";
  static resourceUrl = "/annuaire/structure/";

  static fields() {
    return {
      id: this.uid(),
      structure_type: this.attr(),
      structure_region: this.attr(),
      structure_departement_entite: this.attr(),
      structure_epci: this.attr(),
      structure_commune: this.attr(),
      structure_cle: this.attr(),
      structure_ebe: this.attr(),
      structure_texte: this.attr(),
      name: this.attr(),
    };
  }

  get entity() {
    if (this.structure_cle) return Cle.find(this.structure_cle);
    if (this.structure_ebe) return Ebe.find(this.structure_ebe);
    if (this.structure_departement_entite) return DepartementEntite.find(this.structure_departement_entite);
    if (this.structure_epci) return Epci.find(this.structure_epci);
    if (this.structure_commune) return Commune.find(this.structure_commune);
    if (this.structure_region) return Region.find(this.structure_region);
    return null;
  }

  get type() {
    return ContactProfileStructureType.find(this.structure_type);
  }

  get suiviLink() {
    if (!this.type.isSuiviAvailable) return null;
    return {
      name: "etcld-suivi-territoire-item",
      params: {
        type: this.structure_cle ? "cle" : this.structure_ebe ? "ebe" : this.structure_departement_entite ? "departement" : null,
        id: this.structure_cle ?? this.structure_ebe ?? this.structure_departement_entite ?? null,
      },
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async search(search, params) {
        const response = await this.get(this.model.resourceUrl, { params: { search, ...params } });
        return (response.entities[this.model.entity] ?? []).map((entity) => new ContactProfileStructure(entity));
      },
    },
  };
}
