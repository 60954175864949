export const moveItem = (array, from, to) => {
  const copy = [...array];
  copy.splice(to, 0, ...copy.splice(from, 1));

  return copy;
};

export const toDistinct = (array = [], key) => {
  return [...new Map(array.map((item) => [item[key], item])).values()];
};

export const formatDisplay = (array, separator = ", ", lastSeparator = " et ") => {
  const startItems = array.slice(0, -1);
  const endItem = array.at(-1);
  const startItemsString = startItems.join(separator);
  return [startItemsString, endItem].filter(Boolean).join(lastSeparator);
};

export const isIntersecting = (a = [], b = []) => {
  return a.some(Set.prototype.has, new Set(b));
};

export const sortByField = (field) => {
  return (a, b) => {
    if (a[field] > b[field]) return -1;
    if (a[field] < b[field]) return 1;
    return 0;
  };
};
