<template>
  <a
    :href="`mailto:Support%20NotreXP<${equipe}@etcld.fr>?body=Attention%2C+il+n%27est+pas+recommand%C3%A9+d%27envoyer+sur+cette+adresse+mail+de+pi%C3%A8ce-jointe+contenant+des+donn%C3%A9es+personnelles.%0D%0ASi+toutefois+vous+avez+besoin+de+transmettre+un+document+ou+un+fichier+contenant+des+donn%C3%A9es+personnelles%2C+n%27h%C3%A9sitez+pas+%C3%A0+utiliser+le+service+suivant+%3A+https%3A%2F%2Fprivatebin.etcld.fr`"
    target="_blank noreferrer"
    >{{ equipe }}@etcld.fr</a
  >
</template>

<script>
export default {
  props: {
    equipe: {
      type: String,
      default: "notrexp",
    },
  },
};
</script>
