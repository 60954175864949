import Vue from "vue";

import { formatDisplay as formatArray } from "@/helpers/array";
import { formatBytes } from "@/helpers/conversions";
import { formatDatetime, formatToDisplay } from "@/helpers/dates";
import { splitPhoneNumber } from "@/helpers/phoneNumber";
import { formatDecimal } from "@/components/utils/vuetify/InputNumberOverride";

Vue.filter("formatYear", function (value) {
  if (typeof value == "string") {
    return value.split("-")[0];
  }

  if (value instanceof Date) {
    return value.getFullYear();
  }

  return value;
});

Vue.filter("formatDate", function (value, dateType) {
  if (typeof value == "string") {
    return formatToDisplay(value, dateType);
  }

  if (value instanceof Date) {
    let strValue = value.toISOString().split("T")[0];
    return formatToDisplay(strValue, dateType);
  }

  return value;
});

Vue.filter("formatLongDate", function (value) {
  if (typeof value === "string") {
    value = new Date(value);
  }

  if (value instanceof Date) {
    return value.toLocaleDateString("fr", { day: "numeric", month: "long", year: "numeric" });
  }

  return value;
});

Vue.filter("formatDatetime", function (value) {
  return formatDatetime(value);
});

Vue.filter("formatLongMonth", function (value) {
  if (typeof value == "string") {
    value = new Date(value);
  }

  if (value instanceof Date) {
    return value.toLocaleDateString("fr", { month: "long", year: "numeric" });
  }

  return value;
});

Vue.filter("formatMonth", (value) => {
  let result = value;
  if (typeof value == "string") {
    result = new Date(value);
  }

  return result instanceof Date ? result.toLocaleDateString("fr", { month: "long" }) : result;
});

Vue.filter("formatSiren", (value) => {
  if (!value) return "";
  return value.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
});

Vue.filter("formatSiret", function (value) {
  if (!value) return "";
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{5})/, "$1 $2 $3 $4");
});

Vue.filter("formatAddress", (value) => {
  if (!value || !Object.values(value).some(Boolean)) return "";
  const { adresse, adresse_complementaire, code_postal, ville } = value;
  return `${adresse}${adresse_complementaire ? `, ${adresse_complementaire}` : ""}, ${code_postal} ${ville}`;
});

Vue.filter("formatPhone", (value) => {
  if (!value) return "";
  if (!value.startsWith("+")) return value.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");
  const [countryCode, phoneNumber] = splitPhoneNumber(value);
  const spacedPhoneNumber = phoneNumber.replace(/(\d)(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");
  return [countryCode, spacedPhoneNumber].join(" ");
});

Vue.filter("pluralize", function (amount, plural = "s", singular = "") {
  return amount > 1 ? plural : singular;
});

Vue.filter("truncate", function (text, length) {
  return text.length < length ? text : text.slice(0, length).concat("...");
});

Vue.filter("formatIBAN", (value) => {
  return value
    .replace(/[^\dA-Z]/g, "")
    .replace(/(.{4})/g, "$1 ")
    .trim();
});

Vue.filter("formatArray", (value, separator, lastSeparator) => {
  if (!value || value?.length === 0) return "";
  return formatArray(value, separator, lastSeparator);
});

Vue.filter("formatBytes", (value) => {
  if (!value) return "";
  return formatBytes(value);
});

Vue.filter("formatNumber", (value, type = "float", decimals = 2, delimiter = "\u00a0", nullable = false) => {
  return formatDecimal(value, type, decimals, delimiter, nullable);
});
